import secureClient from "@bukalapak/secure-client/dist";

declare global {
  interface Window {
    __ACCOUNTS_FE_CONTEXT__: any;
    APP: any;
    API: any;
  }
}

const element: HTMLElement | null = document.querySelector(
  "meta[name=csrf-token]",
);
if (element instanceof HTMLMetaElement) {
  element.content;
}

const { APP, user } = window.__ACCOUNTS_FE_CONTEXT__;
const { apiURL, apiAuthEndpoint, env, clientId } = APP;

window.APP = window.APP || APP;
window.API = window.API || {};

const atmInstance = new secureClient.AccessTokenManager({
  userId: user.id,
  env: env,
  authEndpoint: apiAuthEndpoint,
  appId: clientId,
  authToken: document.querySelector<HTMLMetaElement>("meta[name=csrf-token]")
    .content,
});

secureClient.wrapSecureClientInterface.setup(atmInstance, apiURL);
window.API.wrap = secureClient.wrapSecureClientInterface;
